import React from 'react'

import { Trans } from '@lingui/macro'

import { muFlagIcon, vcFlagIcon } from '../../../../../Icon'
import fsaImage from './assets/logo_fsa.png'
import fscImage from './assets/logo_fsc.png'
import { RegulationCardProps } from './components'

export const REGULATION_CARDS: RegulationCardProps[] = [
  {
    country: 'Mauritius',
    flag: muFlagIcon,
    id: '1234567889',
    imageSrc: fscImage,
    title: <Trans>Financial Services Commission (FSC)</Trans>,
  },
  {
    country: 'St. Vincent and Grenadines',
    flag: vcFlagIcon,

    id: '398435345',
    imageSrc: fsaImage,
    title: <Trans>Financial Services Authority (FSA)</Trans>,
  },
]
